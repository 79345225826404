@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.chtp-zaloguj {
    .chtp-zaloguj__title {
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px; }
    .chtp-zaloguj__form {
        input {
            width: 100%;
            height: 46px;
            padding: 15px;
            margin-bottom: 30px;
            border: 2px solid $violet;
            font-weight: 700; }
        input[type="submit"] {
            padding: 10px; } }
    .chtp-zaloguj__facebook-button, .chtp-zaloguj__google-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; }
    .chtp-zaloguj__facebook-button {
        margin-bottom: 30px;
        border-radius: 5px;
        background: #1877f2;
        font-family: 'Roboto', sans-serif;
        img {
            height: 46px;
            width: 46px; }
        span {
            color: white;
            font-size: 20px;
            font-weight: 700; } }
    .chtp-zaloguj__google-button {
        margin-bottom: 30px;
        display: flex;
        border-radius: 5px;
        background: white;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        img {
            height: 46px; }
        span {
            color: #777;
            font-size: 20px;
            text-align: center;
            font-family: 'Roboto', sans-serif; } }
    .chtp-zaloguj__recovery-text {
        padding-bottom: 50px;
        text-align: center; }
    .chtp-zaloguj__wez-udzial-container {
        padding: 60px 0;
        background: $violet;
        p {
            color: white;
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            line-height: 1.5; }
        .chtp__take-part {
            .chtp__button {
                max-width: 356px;
                border: 0px;
                background: white;
                color: $violet; } } } }

@media(min-width: 1050px) {
    .chtp-zaloguj {
        max-width: 1160px;
        margin: auto;
        padding-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        > div {
            width: 50%; }
        > div:nth-child(2) {
            border: 2px solid $violet; }
        > div:nth-child(3) {
            display: flex;
            palign-items: center;
            .site-container {
                max-width: 90%; } }
        .chtp-zaloguj__title {
            width: 100%;
            font-size: 64px; }
        .chtp-zaloguj__form {
            max-width: 356px;
            margin: auto;
            padding-top: 40px;
            input {
                max-width: 356px; } }
        .chtp-zaloguj__facebook-button, .chtp-zaloguj__google-button {
            max-width: 356px;
            margin: 0 auto 30px; } } }

@media(min-width: 1050px) {
    .chtp-przypomnij {
        .chtp-zaloguj__title {
            width: 100%;
            font-size: 36px; } } }
