.chtp-home__main-photo {
    height: 100vw;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-image: url("../assets/home_bg_1.jpg");
    background-size: cover;
    text-align: center;
    .chtp-home__main-title {
        color: white;
        line-height: 1.5;
        text-shadow: 4px 4px 3px rgba(51, 0, 111, 0.6); } }

@media(min-width: 650px) {
    .chtp-home__main-photo {
        height: 90vw;
        padding-bottom: 60px;
        .chtp-home__main-title {
            font-size: 48px; } } }

@media(min-width: 1050px) {
    .chtp-home__main-photo {
        height: 600px;
        justify-content: left;
        background-image: url("../assets/home_bg_2.jpg");
        background-position: center;
        .chtp-home__main-title {
            width: 1160px;
            max-width: 90vw;
            margin: auto;
            font-size: 64px;
            text-align: left; } } }

.chtp-home__o-konkursie {
    padding: 40px 0 80px;
    &-text {
        .chtp-home__o-konkursie-title-container {
            padding-bottom: 40px;
            display: flex;
            justify-content: center;
            align-items: center; }
        .chtp-home__o-konkursie-description {
            padding-bottom: 40px;
            font-size: 14px;
            line-height: 1.5; }
        .chtp-home__o-konkursie-list {
            padding-bottom: 20px;
            .chtp-home__o-konkursie-list-item {
                padding-bottom: 15px;
                display: flex;
                gap: 10px;
                p:nth-child(1) {
                    height: 30px;
                    width: 30px;
                    min-width: 30px;
                    border-radius: 50%;
                    background: linear-gradient(90deg, #FF6B00 0%, #EA1D76 100%);
                    color: white;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 30px;
                    text-align: center; }
                p:nth-child(2) {
                    padding-top: 5px;
                    font-size: 16px;
                    line-height: 1.5; } } } }
    &-photo {
        margin-top: 40px;
        img {
            width: 100%; } }
    &-stripe-container {
        position: relative;
        margin-top: 60px;
        margin-bottom: 20px;
        .chtp-home__o-konkursie-stripe {
            position: relative;
            height: 13px;
            background: rgba(#EA1D76, 0.3);
            .chtp-home__o-konkursie-stripe-items {
                position: absolute;
                top: 50%;
                width: 100%;
                display: flex;
                justify-content: space-between;
                transform: translateY(-50%);
                .chtp-home__o-konkursie-stripe-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    display: grid;
                    place-items: center;
                    border-radius: 50%;
                    background: $violet;
                    font-size: 14px;
                    line-height: 12px;
                    font-weight: 700;
                    text-align: center;
                    p {
                        color: white; }
                    span {
                        position: absolute;
                        top: 120%;
                        font-size: 12px;
                        font-weight: 700; } } } } } }
@media(min-width: 650px) {
    .chtp-home__o-konkursie {
        .chtp-home__o-konkursie-columns {
            .chtp-home__o-konkursie-text {
                .chtp-home__o-konkursie-description {
                    font-size: 16px; } }
            .chtp-home__o-konkursie-photo {
                img {
                    max-height: 80vw;
                    object-fit: cover; } } }
        .chtp-home__o-konkursie-stripe-container {
            .chtp-home__o-konkursie-stripe-items {
                .chtp-home__o-konkursie-stripe-item {
                    span {
                        font-size: 14px;
                        line-height: 16px; } } } } } }

@media(min-width: 1050px) {
    .chtp-home__o-konkursie {
        .chtp-home__o-konkursie-columns {
            display: flex;
            justify-content: space-between;
            padding-bottom: 40px;
            .chtp-home__o-konkursie-text {
                flex-basis: 50%;
                padding-right: 40px;
                .chtp-home__o-konkursie-title-container {
                    justify-content: flex-start;
                    font-size: 24px; }
                .chtp-home__o-konkursie-description {
                    padding-bottom: 30px;
                    font-size: 18px; }
                .chtp-home__o-konkursie-list {
                    .chtp-home__o-konkursie-list-item {
                        p:nth-child(2) {
                            padding-top: 0px;
                            font-size: 18px; } } } }
            .chtp-home__o-konkursie-photo {
                margin-top: 0px;
                img {
                    height: 100%;
                    max-height: initial; } } }
        .chtp-home__o-konkursie-stripe-container {
            margin-top: 80px;
            margin-bottom: 80px;
            .chtp-home__o-konkursie-stripe-items {
                .chtp-home__o-konkursie-stripe-item {
                    width: 80px;
                    height: 80px;
                    font-size: 20px;
                    span {
                        font-size: 24px;
                        line-height: 28px; } } } } } }

$marzycieleItemSize: 280px;
$marzycieleItemSizeDesktop: 360px;
.chtp-home__marzyciele {
    margin-top: 40px;
    .chtp-home__marzyciele-title-container {
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $orange;
        h2 {
            color: white; } }
    .chtp-home__marzyciele-items {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        gap: 30px;
        flex-wrap: wrap;
        .chtp-home__marzyciele-item {
            position: relative;
            width: $marzycieleItemSize;
            height: $marzycieleItemSize;
            overflow: hidden;
            .chtp-home__marzyciele-item-text {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 10px 20px;
                transition: 0.2s;
                display: flex;
                justify-content: space-between;
                background: linear-gradient(90deg, rgba(255, 107, 0, 0.8) 0%, rgba(234, 29, 118, 0.8) 100%);
                p {
                    color: white;
                    font-size: 16px;
                    font-weight: 700;
                    user-select: none; } }
            .chtp-home__marzyciele-item--hover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 20px;
                transform: translateX(-100%);
                transition: 0.2s;
                background: linear-gradient(90deg, rgba(234, 29, 118, 0.8) 0%, rgba(255, 107, 0, 0.8) 100%);
                p:nth-child(1) {
                    padding-bottom: 20px;
                    color: white;
                    font-size: 16px;
                    font-weight: 700;
                    user-select: none; }
                p:nth-child(2) {
                    color: white;
                    font-size: 14px;
                    user-select: none; } }
            &:hover {
                .chtp-home__marzyciele-item-text {
                    transform: translateX(100%); }
                .chtp-home__marzyciele-item--hover {
                    transform: translateX(0); } } }
        .chtp-home__marzyciele-item {
            background-size: cover; }
        .chtp-home__marzyciele-item:nth-child(1) {
            background-image: url("../assets/home-marzyciel1.jpg"); }
        .chtp-home__marzyciele-item:nth-child(2) {
            background-image: url("../assets/home-marzyciel1.jpg"); }
        .chtp-home__marzyciele-item:nth-child(3) {
            background-image: url("../assets/home-marzyciel1.jpg"); }
        .chtp-home__marzyciele-item:nth-child(4) {
            background-image: url("../assets/home-marzyciel1.jpg"); }
        .chtp-home__marzyciele-item:nth-child(5) {
            background-image: url("../assets/home-marzyciel1.jpg"); }
        .chtp-home__marzyciele-item:nth-child(6) {
            background-image: url("../assets/home-marzyciel1.jpg"); } } }

@media(min-width: 1050px) {
    .chtp-home__marzyciele {
        .chtp-home__marzyciele-items {
            .chtp-home__marzyciele-item {
                width: $marzycieleItemSizeDesktop;
                height: $marzycieleItemSizeDesktop; } } } }
