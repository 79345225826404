.chtp__grid-gallery {
    display: grid;
    gap: 40px;
    img {
        width: 100%;
        height: 60vw;
        object-fit: cover;
        cursor: pointer; } }

@media(min-width: 650px) {
    .chtp__grid-gallery {
        grid-template-columns: repeat(2, 1fr);
        img {
            height: 30vw;
            &:nth-child(3n - 2) {
                height: 60vw;
                grid-column-start: 1;
                grid-column-end: 3; } } } }

@media(min-width: 1050px) {
    .chtp__grid-gallery {
        grid-template-columns: repeat(6, 1fr);
        img {
            height: 250px;
            &:nth-child(3n - 2) {
                height: 250px;
                grid-column-start: initial;
                grid-column-end: initial; }
            &:nth-child(5n - 4) {
                height: 350px;
                grid-column-start: 1;
                grid-column-end: 4; }
            &:nth-child(5n - 3) {
                height: 350px;
                grid-column-start: 4;
                grid-column-end: 7; }
            &:nth-child(5n - 2) {
                height: 250px;
                grid-column-start: 1;
                grid-column-end: 3; }
            &:nth-child(5n - 1) {
                height: 250px;
                grid-column-start: 3;
                grid-column-end: 5; }
            &:nth-child(5n) {
                height: 250px;
                grid-column-start: 5;
                grid-column-end: 7; } } } }

// galeria zdjęć
.chtp__grid-gallery-overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    transform: translateX(100%);
    opacity: 0;
    transition: 0.2s ease-in-out;
    background: rgba(0,0,0,0.8);
    z-index: 200;
    img {
        max-height: 80vh;
        max-width: 100vw;
        object-fit: cover; }
    &-arrow {
        position: absolute;
        top: 50%;
        display: none;
        transform: translateY(-50%);
        height: 150px;
        width: 50px;
        color: $orange;
        text-align: center;
        font-size: 150px;
        line-height: 120px;
        user-select: none;
        cursor: pointer; }
    &-arrow--left {
        left: 50px; }
    &-arrow--right {
        right: 50px; } }
.chtp__grid-gallery-overlay--active {
    transform: translateX(0%);
    opacity: 1; }

@media(min-width: 1050px) {
    .chtp__grid-gallery-overlay {
        &-arrow {
            display: inline; } } }
