.chtp-404 {
    padding-bottom: 40px;
    text-align: center;
    line-height: 1.5;
    .chtp-404__title {
        padding: 40px 0 40px;
        font-size: 42px; }
    .chtp-404__text {
        font-size: 16px; } }

@media(min-width: 1050) {
    .chtp-404 {
        .chtp-404__title {
            font-size: 64px; }
        .chtp-404__text {
            font-size: 24px; } } }
