.chtp-partners__logos {
    width: 100%;
    padding: 60px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    img {
        width: 100%; } }

@media(min-width: 650px) {
    .chtp-partners__logos {
        grid-template-columns: repeat(4, 1fr); } }

@media(min-width: 1050px) {
    .chtp-partners__logos {
        width: 80%;
        margin: auto;
        grid-template-columns: repeat(4, 1fr); } }
