.kim-jestesmy__activities-columns {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    img {
        width: 100%;
        height: auto; }
    h2 {
        padding: 20px 0;
        font-size: 18px;
        line-height: 1; }
    p {
        padding-bottom: 20px;
        font-size: 14px;
        line-height: 1.5; } }

@media(min-width: 650px) {
    .kim-jestesmy__activities-columns {
        padding: 20px 0;
        gap: 30px;
        .kim-jestesmy__activities-item {
            display: flex;
            img {
                width: 40%;
                min-width: 40%;
                object-fit: cover; }
            .kim-jestesmy__activities-item-text {
                padding: 0 20px; } } } }

@media(min-width: 1050px) {
    .kim-jestesmy__activities-columns {
        margin-top: 40px;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
        .kim-jestesmy__activities-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-basis: calc(100%/3);
            img {
                width: 100%; }
            .kim-jestesmy__activities-item-text {
                padding: 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-start;
                flex-grow: 1;
                > h2, p, a {
                    width: 100%; }
                h2 {
                    line-height: 1; }
                p {}
                a {
                    padding-top: 20px;
                    align-self: flex-end; } } } } }

.kim-jestesmy__social-columns {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px 0;
    .kim-jestesmy__social-item {
        position: relative;
        width: 48%;
        padding: 20px 10px 10px;
        border: 2px solid $orange;
        .kim-jestesmy__social-logo-container {
            position: absolute;
            top: -2px;
            left: -2px;
            width: 55px;
            height: 55px;
            background: white;
            .kim-jestesmy__social-logo {
                width: 100%;
                height: 100%;
                display: grid;
                place-items: center;
                border-radius: 50%;
                background: $orange; } }
        .kim-jestesmy__social-title {
            padding-left: 45px;
            padding-bottom: 30px;
            font-size: 16px; }
        .kim-jestesmy__social-text {
            padding-bottom: 30px;
            font-size: 12px;
            line-height: 1.5; } } }

@media(min-width: 650px) {
    .kim-jestesmy__social-columns {
        .kim-jestesmy__social-item {
            padding: 20px;
            .kim-jestesmy__social-title {
                padding-left: 55px; }
            .kim-jestesmy__social-text {
                font-size: 14px; } } } }

@media(min-width: 1050px) {
    .kim-jestesmy__social-columns {
        flex-wrap: nowrap;
        gap: 20px;
        .kim-jestesmy__social-item {
            width: initial;
            padding: 20px;
            border: 3px solid $orange;
            .kim-jestesmy__social-logo-container {
                top: -3px;
                left: -3px;
                width: 90px;
                height: 90px;
                .kim-jestesmy__social-logo {
                    img {
                        transform: scale(1.5); } } }
            .kim-jestesmy__social-title {
                padding-left: 85px;
                padding-bottom: 50px;
                font-size: 26px; }
            .kim-jestesmy__social-text {
                font-size: 16px; } } } }

.kim-jestesmy__numbers {
    background: $orange;
    text-align: center;
    .kim-jestesmy__numbers-km-logo {
        width: 135px;
        padding-bottom: 40px; }
    .kim-jestesmy__numbers-columns {
        display: flex;
        justify-content: space-between;
        .kim-jestesmy__numbers-item {
            flex-basis: 33%;
            text-align: center;
            img {
                width: 70px; }
            p {
                padding-top: 10px;
                color: white;
                font-size: 18px;
                line-height: 1.5; } } } }

@media(min-width: 1050px) {
    .kim-jestesmy__numbers {
        padding: 80px;
        .kim-jestesmy__numbers-km-logo {
            width: 270px;
            padding-bottom: 60px; }
        .kim-jestesmy__numbers-columns {
            .kim-jestesmy__numbers-item {
                img {
                    width: 170px; }
                p {
                    font-size: 40px; } } } } }

.kim-jestesmy__opinions {
    padding: 40px 0 0;
    .kim-jestesmy__opinions-columns {
        .kim-jestesmy__opinions-text {
            padding-bottom: 30px;
            h2 {
                padding-bottom: 20px;
                font-size: 24px; }
            p {
                font-size: 14px; }
            .chtp__white-button {
                display: none; } }
        .kim-jestesmy__opinions-stars-container {
            text-align: center;
            p {
                font-size: 54px;
                padding-bottom: 10px; }
            img {
                max-width: 240px;
                margin-bottom: 30px; } } } }

@media(min-width: 650px) {
    .kim-jestesmy__opinions {
        .kim-jestesmy__opinions-columns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .kim-jestesmy__opinions-text {
                padding-right: 20px;
                flex-basis: 50%;
                h2 {
                    font-size: 32px; }
                p {
                    padding-bottom: 20px;
                    font-size: 18px; }
                .chtp__white-button {
                    max-width: 344px;
                    display: block; } }
            .kim-jestesmy__opinions-stars-container {
                flex-basis: 50%;
                p {
                    font-size: 108px; }
                img {
                    width: 473px;
                    max-width: 90%; }
                .chtp__white-button {
                    display: none; } } } } }
