.chtp-main-photo {
    height: 200px;
    padding: 40px;
    display: grid;
    place-items: center;
    background-size: cover;
    background-position: center;
    h1 {
        color: white;
        line-height: 54px;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 2px 2px 1.5px rgba(51, 0, 111, 0.6); } }
// kim jesteśmy background
.chtp-main-photo--kim-jestesmy {
    background-image: url('../assets/kim-jestesmy_bg_1.jpg'); }
// o akcji background
.chtp-main-photo--o-akcji {
    background-image: url('../assets/o-akcji_bg_1.jpg'); }
// error404 background
.chtp-main-photo--404 {
    display: flex;
    background-image: url('../assets/404_bg_1.png'); }
// partnerzy background
.chtp-main-photo--partnerzy {
    background-image: url('../assets/partnerzy_bg_1.jpg'); }
// harmonogram
.chtp-main-photo--harmonogram {
    background-image: url('../assets/harmonogram_bg_1.jpg'); }
// zglos sie
.chtp-main-photo--zglos-sie {
    background-image: url('../assets/zglos-sie_bg_1.jpg'); }

@media(min-width: 1050px) {
    .chtp-main-photo {
        height: 400px;
        h1 {
            font-size: 64px;
            line-height: 72px; } }
    // kim jesteśmy background
    .chtp-main-photo--kim-jestesmy {
        background-image: url('../assets/kim-jestesmy_bg_2.jpg'); }
    // o akcji background
    .chtp-main-photo--o-akcji {
        background-image: url('../assets/o-akcji_bg_2.jpg'); }
    // error404 background
    .chtp-main-photo--404 {
        display: grid;
        place-items: center;
        background-image: url('../assets/404_bg_2.png');
        h1 {
            font-size: 128px; } }
    // partnerzy background
    .chtp-main-photo--partnerzy {
        background-image: url('../assets/partnerzy_bg_2.jpg'); }
    // harmonogram
    .chtp-main-photo--harmonogram {
        background-image: url('../assets/harmonogram_bg_2.jpg'); }
    // zglos sie
    .chtp-main-photo--zglos-sie {
        background-image: url('../assets/zglos-sie_bg_2.jpg'); } }

