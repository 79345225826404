@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;700&display=swap');

$violet: #33006f;
$yellow: #ffc627;
$pink: #EA1D76;
$orange: #ff6b00;
$lavender: #D9D9D9;

$linearBackground: linear-gradient(90deg, #FF6B00 0%, #EA1D76 100%);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif; }

body {
    color: $violet; }

.body-modal-open {
    height: 100vh;
    overflow-y: hidden; }

ul {
    list-style: none; }

a {
    transition: 0.2s;
    color: $violet;
    text-decoration: none;
    &:hover {
        color: $pink; } }

.underline-link {
    color: $violet;
    text-decoration: underline;
    &:hover {
        color: $pink;
        text-decoration: none; } }

.site-container {
    width: 90vw;
    max-width: 1160px;
    margin: auto; }

.chtp__container-padding {
    padding: 40px 0; }

@media(min-width: 1050px) {
    .chtp__container-padding {
        padding: 60px 0; } }

.chtp__break-line {
    height: 5px;
    width: 100%;
    background: $linearBackground; }

@media(min-width: 650px) {
    .chtp__break-line {
        height: 15px; } }

.chtp__text-photo {
    display: flex;
    flex-direction: column;
    .chtp__text-photo-text {
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        h2 {
            font-size: 18px; }
        p {
            font-size: 14px; } }
    .chtp__text-photo-photo {
        max-width: 100%;
        padding-bottom: 40px; } }

@media(min-width: 650px) {
    .chtp__text-photo {
        width: 100%;
        align-items: center;
        .chtp__text-photo-text {
            padding-bottom: 0;
            padding-right: 40px;
            h2 {
                font-size: 24px; }
            p {
                font-size: 18px; } }
        .chtp__text-photo-photo {
            width: 100%;
            margin: 40px;
            padding-bottom: 0; } }
    .chtp__text-photo--reverse {
        flex-direction: column;
        .chtp__text-photo-text {
            padding-right: 0; } } }
@media(min-width: 1050px) {
    .chtp__text-photo {
        width: 100%;
        flex-direction: row;
        align-items: center;
        .chtp__text-photo-text {
            padding-bottom: 0;
            padding-right: 40px;
            h2 {
                font-size: 24px; }
            p {
                font-size: 18px; } }
        .chtp__text-photo-photo {
            min-width: 50%;
            width: 50%;
            margin: 0;
            padding-bottom: 0; } }
    .chtp__text-photo--reverse {
        flex-direction: row-reverse;
        .chtp__text-photo-text {
            padding-right: 0;
            padding-left: 40px; } } }

.chtp__take-part {
    width: 100%;
    padding: 60px 0 0;
    text-align: center; }

.chtp__button {
    width: 100%;
    transition: 0.2s;
    padding: 10px 20px;
    border: 2px solid $violet;
    background: $violet;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    &:hover {
        background: white;
        color: $violet; } }

.chtp__white-button {
    background: white;
    color: $violet;
    &:hover {
        background: $violet;
        color: white; } }

@media(min-width: 1050px) {
    .chtp__white-button, .chtp__button {
        max-width: 410px;
        font-size: 20px; } }

.chtp__chtp-numbers {
    padding-top: 40px;
    display: flex;
    gap: 10px;
    .chtp__chtp-numbers-item {
        flex-basis: calc(100% / 3);
        text-align: center;
        &-num {
            padding-bottom: 10px;
            font-size: 26px; }
        &-text {
            font-size: 14px; } } }

@media(min-width: 650px) {
    .chtp__chtp-numbers {
        padding-top: 60px;
        .chtp__chtp-numbers-item {
            &-num {
                font-size: 54px; }
            &-text {
                font-size: 24px; } } } }

@media(min-width: 1050px) {
    .chtp__chtp-numbers {
        .chtp__chtp-numbers-item {
            &-num {
                padding-bottom: 20px;
                font-size: 80px; }
            &-text {
                font-size: 36px; } } } }

.chtp__nagrody {
    .chtp__nagrody-title-container {
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .chtp__nagrody-title {
            font-size: 24px;
            text-align: center; } } }

@media(min-width: 1050px) {
    .chtp__nagrody {
        .chtp__nagrody-title-container {
            padding-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            .chtp__nagrody-title {
                font-size: 32px; } } } }

// padding sprawiający, że menu nie zasłania contentu
.chtp__main {
    padding-top: 80px; }
