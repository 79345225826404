$naviHeight: 80px;

.chtp-header {
    background: white;
    .chtp-header__container {
        position: relative;
        width: 100%;
        position: fixed;
        height: $naviHeight;
        display: grid;
        place-items: center;
        background: white;
        z-index: 200 !important;
        .chtp-header__nav {
            width: 1160px;
            max-width: 90vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .chtp-header__nav-list--desktop {
                display: none; }
            .chtp-header__nav-list--desktop-socials {
                display: none; } }
        .chtp-header__burger {
            &-line {
                position: relative;
                width: 40px;
                height: 10px;
                background: $orange;
                border-radius: 8px;
                &::after, &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 40px;
                    height: 10px;
                    background: $orange;
                    border-radius: 8px; }
                &::before {
                    top: -16px; }
                &::after {
                    top: 16px; } } }
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            display: block;
            background: linear-gradient(90deg, #FF6B00 0%, #EA1D76 100%);
            z-index: 1; } }
    .chtp-header__nav-list--mobile-container {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        padding-top: $naviHeight;
        transform: translateX(-100%);
        transition: 0.3s ease;
        background: $linearBackground;
        text-align: center;
        z-index: 100;
        .chtp-header__nav-list--mobile {
            margin-bottom: 40px;
            li {
                padding: 20px;
                border-bottom: 2px solid white;
                color: white;
                font-weight: 700;
                font-size: 24px;
                text-align: center; } }
        .chtp-header__zaloguj-button {
            height: 60px;
            width: 240px;
            margin-bottom: 30px;
            border: 2px solid white;
            background: rgba(0,0,0,0);
            color: white;
            font-size: 24px;
            font-weight: 700;
            cursor: pointer; }
        .chtp-header__socials {
            img {
                width: 60px;
                height: 60px;
                margin: 0 30px;
                border-radius: 50%; } } }
    .chtp-header__nav-list--mobile-container--active {
        transform: translateY(0);
        position: fixed;
        z-index: 100; }
    .chtp-header__logos {
        display: flex;
        align-items: center;
        gap: 15px;
        > img:nth-child(1) {
            width: 84px; }
        > a:nth-child(3) {
            img {
                width: 70px; } } }
    .chtp__break-line {
        margin-top: 80px; } }



@media(min-width: 650px) {
    .chtp-header {
        .chtp-header__logos {
            > img:nth-child(1) {
                width: 134px; }
            > img:nth-child(3) {
                width: 112px; } } } }

@media(min-width: 1050px) {
    .chtp-header {
        .chtp-header__container {
            .chtp-header__nav {
                .chtp-header__nav-list--desktop {
                    display: flex;
                    gap: 20px;
                    li {
                        font-weight: 700; } }
                .chtp-header__nav-list--desktop-socials {
                    display: flex;
                    gap: 10px;
                    img {
                        width: 46px; }
                    button {
                        font-size: 16px;
                        cursor: pointer; } } }
            .chtp-header__burger {
                display: none; } } } }
