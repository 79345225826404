.chtp-zglos-sie {
    padding-bottom: 40px;
    .chtp-zglos-sie__title, .chtp-zglos-sie__text {
        padding-top: 40px;
        text-align: center; }
    .chtp-zglos-sie__text {
        padding-bottom: 40px; }
    .chtp-zglos-sie__form {
        input, select, textarea {
                width: 100%;
                height: 46px;
                margin-bottom: 30px;
                border: 2px solid $violet;
                color: $violet;
                &:focus {
                    border: 2px solid $orange; } }
        input, textarea {
            padding: 15px; }
        select {
            padding: 0 15px;
            font-weight: 700;
            option {
                font-weight: 700; } }
        textarea {
            width: 100%;
            height: 100px;
            padding-top: 10px;
            resize: none;
            line-height: 1.5; }
        p {
            padding-bottom: 30px;
            font-size: 12px; }
        .chtp-zglos-sie__form-checkbox-section {
            padding-bottom: 30px;
            display: flex;
            input[type="checkbox"] {
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                margin-bottom: 0;
                margin-right: 10px; }
            label {
                font-size: 12px; } }
        .chtp-zglos-sie__form-button-container {
            .chtp__button {
                padding: 10px;
                color: white;
                &:hover {
                    color: $violet; } } } } }

@media(min-width: 650px) {
    .chtp-zglos-sie {
        .chtp-zglos-sie__title, .chtp-zglos-sie__text {
            // padding-top: 40px
 }            // text-align: center
        .chtp-zglos-sie__text {
 }            // padding-bottom: 40px
        .chtp-zglos-sie__form {
            input, select, textarea {
                    // width: 100%
                    // height: 46px
                    // margin-bottom: 30px
                    // border: 2px solid $violet
                    // color: $violet
                    &:focus {
 } }                        // border: 2px solid $orange
            input, textarea {
 }                // padding: 15px
            select {
 }                // padding: 0 15px
            textarea {
                // width: 100%
                height: 150px;
                // padding-top: 10px
                // resize: none
 }                // line-height: 1.5
            p {
                // padding-bottom: 30px
 }                // font-size: 12px
            .chtp-zglos-sie__form-info, .chtp-zglos-sie__form-links {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 0 40px;
                input, select {
                    flex-grow: 1;
                    width: 45%; } }

            .chtp-zglos-sie__form-checkbox-section {
                // padding-bottom: 30px
                // display: flex
                input[type="checkbox"] {
                    // width: 24px
                    // height: 24px
                    // min-width: 24px
                    // min-height: 24px
                    // margin-bottom: 0
 }                    // margin-right: 10px
                label {
 } }                    // font-size: 12px
            .chtp-zglos-sie__form-button-container {
                text-align: center;
                .chtp__button {
                    max-width: 356px;
                    // padding: 10px
 } } } } }                    // color: white

@media(min-width: 1050px) {
    .chtp-zglos-sie {
        .chtp-zglos-sie__title, .chtp-zglos-sie__text {
            // padding-top: 40px
 }            // text-align: center
        .chtp-zglos-sie__text {
 }            // padding-bottom: 40px
        .chtp-zglos-sie__form {
            input, select, textarea {
                    // width: 100%
                    // height: 46px
                    // margin-bottom: 30px
                    // border: 2px solid $violet
                    // color: $violet
                    &:focus {
 } }                        // border: 2px solid $orange
            input, textarea {
 }                // padding: 15px
            select {
 }                // padding: 0 15px
            textarea {
                // width: 100%
                // height: 150px
                // padding-top: 10px
                // resize: none
 }                // line-height: 1.5
            p {
                // padding-bottom: 30px
 }                // font-size: 12px
            .chtp-zglos-sie__form-info, .chtp-zglos-sie__form-links {
                // display: flex
                // justify-content: space-between
                // flex-wrap: wrap
                // gap: 0 40px
                input, select {
                    // flex-grow: 1
 } }                    // width: 45%

            .chtp-zglos-sie__form-checkbox-section {
                // padding-bottom: 30px
                // display: flex
                input[type="checkbox"] {
                    // width: 24px
                    // height: 24px
                    // min-width: 24px
                    // min-height: 24px
                    // margin-bottom: 0
 }                    // margin-right: 10px
                label {
 } }                    // font-size: 12px
            .chtp-zglos-sie__form-button-container {
                // text-align: center
                .chtp__button {
                    // max-width: 356px
                    // padding: 10px
 } } } } }                    // color: white

