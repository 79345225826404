.chtp-footer {
    margin-top: 20px;
    height: 100vh;
    text-align: center;
    .chtp-footer__columns {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .chtp-footer__column {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .chtp-footer__socials {
                display: flex;
                justify-content: center;
                gap: 25px;
                img {
                    width: 76px;
                    height: auto; } } }
        .chtp-footer__logos {
            margin: auto;
            > img {
                width: 175px;
                height: auto; } } }
    .chtp-footer__newsletter-column {
        width: 100%;
        form {
            position: relative; }
        .chtp-footer__newsletter-email-sign {
            display: flex;
            margin-bottom: 20px;
            input {
                height: 46px;
                font-weight: bold;
                border: 2px solid $violet; }
            input[type="text"] {
                padding: 0 10px;
                flex-grow: 2; }
            input[type="submit"] {
                padding: 0 25px;
                background: $violet;
                color: white;
                cursor: pointer; } }
        input[type="checkbox"] {
            position: absolute;
            left: 0;
            top: 66px;
            width: 28px;
            height: 28px;
            opacity: 0.2; }
        label {
            position: relative;
            margin-left: 40px;
            display: inherit;
            font-size: 14px;
            text-align: left;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -40px;
                width: 24px;
                height: 24px;
                border: 2px solid $violet; } }
        input[type="checkbox"]:checked + label {
            &:after {
                content: "✔";
                position: absolute;
                top: 0;
                left: -40px;
                width: 24px;
                height: 24px;
                padding: 4px 2px 0;
                background: $violet;
                color: white;
                font-size: 20px;
                line-height: 20px;
                text-align: center; } } }

    .chtp-footer__copyrights {
        width: 100%;
        padding: 60px;
        text-align: center; } }
@media(min-width: 650px) {
    .chtp-footer {
        height: auto;
        .chtp-footer__columns {
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;
            gap: 40px 20px;
            .chtp-footer__column {
                width: 45%;
                max-width: 45%; }
            .chtp-footer__logos {
                margin: 0;
                > img {
                    margin: auto; } } } } }
@media(min-width: 1050px) {
    .chtp-footer {
        text-align: left;
        .chtp-footer__columns {
            justify-content: space-between;
            flex-wrap: nowrap;
            gap: 20px 20px;
            .chtp-footer__column {
                width: initial;
                max-width: initial; }
            .chtp-footer__newsletter-column {
                width: 280px; } } } }
