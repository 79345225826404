.chtp__nagrody {
    .swiper {
        width: 100%;
        height: 100%;
        margin: auto;

        &-slide {
            height: 100px;
            width: calc(100% + 30px) !important;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            gap: 10px;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            background-size: cover;
            background-position: 50%;
            text-align: center;
            font-size: 18px;
            margin-right: 0px !important;
            padding-right: 35px;
            p {
                color: white;
                font-weight: 700;
                user-select: none; }
            p:nth-child(1) {
                font-size: 16px; }
            p:nth-child(2) {
                font-size: 24px; } }
        &-slide:nth-child(1) {
            background-image: linear-gradient(rgba(#FF6B00,0.2),rgba(#FF6B00,0.2)), url("../assets/nagrody-slider1.jpg"); }
        &-slide:nth-child(2) {
            background-image: linear-gradient(rgba(#FF6B00,0.2),rgba(#FF6B00,0.2)), url("../assets/nagrody-slider1.jpg"); }
        &-slide:nth-child(3) {
            background-image: linear-gradient(rgba(#FF6B00,0.2),rgba(#FF6B00,0.2)), url("../assets/nagrody-slider1.jpg"); }
        &-slide:nth-child(4) {
            background-image: linear-gradient(rgba(#FF6B00,0.2),rgba(#FF6B00,0.2)), url("../assets/nagrody-slider1.jpg"); }
        &-slide:nth-child(5) {
            background-image: linear-gradient(rgba(#FF6B00,0.2),rgba(#FF6B00,0.2)), url("../assets/nagrody-slider1.jpg"); }

        &-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        &-button-prev, &-button-next {
            display: none;
            &::after {
                color: white; } }
        &-pagination {
            bottom: 5px !important;
            &-bullet {
                background: white; } } } }

@media(min-width: 650px) {
    .site-container {
        .swiper {
            &-slide {
                height: 200px;
                p:nth-child(1) {
                    font-size: 24px; }
                p:nth-child(2) {
                    font-size: 36px; } }
            &-pagination {
                bottom: 10px !important; } } } }

@media(min-width: 650px) {
    .site-container {
        .swiper {
            &-slide {
                height: 300px;
                p:nth-child(1) {
                    font-size: 48px; }
                p:nth-child(2) {
                    font-size: 72px; } }
            &-button-prev, &-button-next {
                display: inline; } } } }
