.chtp-harmonogram__title {
    text-align: center; }

.chtp-harmonogram__items {
    position: relative;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .chtp-harmonogram__items-numbers {
        position: absolute;
        left: 30px;
        top: 40px;
        width: 6px;
        height: calc(100% - 300px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: linear-gradient(180deg, #FF6B00 0%, #EA1D6B 100%);
        li {
            position: relative;
            color: white;
            font-weight: 700;
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 25px;
                height: 25px;
                transform: translatex(-50%) translatey(-50%);
                border-radius: 50%;
                background: red;
                font-size: 18px;
                text-align: center;
                line-height: 26px; }
            &:nth-child(1)::before {
                content: '1';
                background: #FF6B02; }
            &:nth-child(2)::before {
                content: '2';
                background: #FC5D15; }
            &:nth-child(3)::before {
                content: '3';
                background: #F84D2A; }
            &:nth-child(4)::before {
                content: '4';
                background: #F33C42; }
            &:nth-child(5)::before {
                content: '5';
                background: #EF2E55; }
            &:nth-child(6)::before {
                content: '6';
                background: #EB1E6B; } } }
    .chtp-harmonogram__item {
        height: 300px;
        padding: 30px 30px 30px 70px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        font-size: 16px;
        &-date {
            padding-bottom: 15px; }
        &-title {
            padding: 15px 0;
            border-top: 1px solid $violet; }
        &-text {
            padding-top: 15px;
            border-top: 1px solid $violet;
            font-size: 14px; } } }

@media(min-width: 650px) {
    .chtp-harmonogram__items {
        .chtp-harmonogram__items-numbers {
            height: calc(100% - 250px); }
        .chtp-harmonogram__item {
            height: 250px; } } }

@media(min-width: 1050px) {
    .chtp-harmonogram__items {
        .chtp-harmonogram__items-numbers {
            left: 60px;
            top: 88px;
            height: calc(100% - 184px);
            li {
                &:before {
                    width: 50px;
                    height: 50px;
                    font-size: 32px;
                    line-height: 48px; } } }
        .chtp-harmonogram__item {
            height: 184px;
            padding: 20px 30px 20px 90px;
            &-inner {
                display: flex;
                align-items: center; }
            &-date {
                width: 20%;
                padding: 0 40px;
                font-size: 24px; }
            &-title {
                width: 40%;
                padding: 0 40px;
                border-top: 0px;
                border-left: 1px solid $violet;
                text-align: center;
                line-height: 1.5;
                font-size: 24px; }
            &-text {
                padding: 0 40px;
                border-top: 0px;
                border-left: 1px solid $violet;
                line-height: 1.5;
                font-size: 18px; } } } }

.chtp-harmonogram__terms {
    padding: 60px 0;
    .chtp-harmonogram__terms-title {
        padding-bottom: 40px;
        text-align: center; }
    .chtp-harmonogram__terms-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .chtp-harmonogram__terms-item {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            .chtp-harmonogram__terms-item-text {
                font-size: 14px;
                font-weight: 700; }
            .chtp-harmonogram__terms-item-photo {
                min-width: 45%; } }
        .chtp-harmonogram__arrow-container-mobile {
            width: 45%;
            display: grid;
            place-items: center; }
        .chtp-harmonogram__arrow-desktop {
            display: none; } } }

@media(min-width: 650px) {
    .chtp-harmonogram__terms {
        width: 650px;
        margin: auto;
        .chtp-harmonogram__terms-title {}
        .chtp-harmonogram__terms-container {
            position: relative;
            margin: auto;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 0px 0;
            .chtp-harmonogram__terms-item {
                width: 300px;
                flex-direction: column;
                align-items: flex-start;
                .chtp-harmonogram__terms-item-text {
                    width: 80%;
                    font-size: 16px;
                    line-height: 1.5;
                    text-align: center; }
                .chtp-harmonogram__terms-item-photo {
                    min-width: 45%;
                    width: 80%; } }
            .chtp-harmonogram__terms-item2 {
                padding-top: 100px;
                align-items: flex-end; }
            .chtp-harmonogram__terms-item4 {
                padding-top: 60px;
                align-items: flex-end; }
            .chtp-harmonogram__arrow-container-mobile {
                display: none; }
            .chtp-harmonogram__arrow-desktop {
                position: absolute;
                display: inline;
                left: 250px;
                width: 150px; }
            .chtp-harmonogram__arrow-desktop1 {
                top: 120px; }
            .chtp-harmonogram__arrow-desktop2 {
                top: -80px; }
            .chtp-harmonogram__arrow-desktop3 {
                top: 100px; } } } }

@media(min-width: 1050px) {
    .chtp-harmonogram__terms {
        width: 900px;
        .chtp-harmonogram__terms-container {
            .chtp-harmonogram__terms-item {
                width: 380px; }
            .chtp-harmonogram__terms-item2 {
                padding-top: 140px; }
            .chtp-harmonogram__terms-item4 {
                padding-top: 160px; }
            .chtp-harmonogram__arrow-desktop {
                left: 320px;
                width: 240px; }
            .chtp-harmonogram__arrow-desktop1 {
                top: 160px; }
            .chtp-harmonogram__arrow-desktop2 {
                top: -120px; }
            .chtp-harmonogram__arrow-desktop3 {
                top: 160px; } } } }
